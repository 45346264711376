/** @jsx jsx */
/**
 * Customizations:
 *
 * - changed to preferred filter order:
 *   Style, characteristics, varieties, regions, subregions, vintage
 * - removed unwanted filters for characteristics and designations.
 */

import { Box } from '@bottlebooks/gatsby-theme-base';
import ProductCharacteristicsFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductCharacteristicsFacet';
import ProductCountryFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductCountryFacet';
import {
  characteristicsToggled,
  countryToggled,
  grapeVarietyToggled,
  priceChanged,
  productTypeToggled,
  regionToggled,
  subregionToggled,
  vintageToggled,
} from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/productFilterReducer';
import ProductGrapeVarietiesFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductGrapeVarietiesFacet';
import ProductPriceFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductPriceFacet';
import ProductRegionFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductRegionFacet';
import ProductSubregionFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductSubregionFacet';
import ProductTypeFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductTypeFacet';
import ProductVintageFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductVintageFacet';
import { jsx } from 'theme-ui';

export * from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductFilters';

export default function ProductFilters({
  facets,
  dispatch,
  state,
  totalCount,
  count,
  ...rest
}) {
  return (
    <Box {...rest}>
      <ProductPriceFacet
        values={state.price}
        onChange={(values) => dispatch(priceChanged(values))}
      />

      <ProductTypeFacet
        allValues={facets.productType}
        selectedValues={state.productTypes}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(productTypeToggled(value), isActive)
        }
      />

      <ProductCharacteristicsFacet
        allValues={facets.characteristics}
        selectedValues={state.characteristics}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(characteristicsToggled(value), isActive)
        }
      />

      <ProductGrapeVarietiesFacet
        allValues={facets.grapeVarieties}
        selectedValues={state.grapeVarieties}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(grapeVarietyToggled(value), isActive)
        }
      />

      <ProductCountryFacet
        allValues={facets.country}
        selectedValues={state.countries}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(countryToggled(value), isActive)
        }
      />
      <ProductRegionFacet
        allValues={facets.region}
        selectedValues={state.regions}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) => dispatch(regionToggled(value), isActive)}
      />
      <ProductSubregionFacet
        allValues={facets.subregion}
        selectedValues={state.subregions}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(subregionToggled(value), isActive)
        }
      />
      <ProductVintageFacet
        allValues={facets.vintage}
        selectedValues={state.vintages}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(vintageToggled(value), isActive)
        }
      />
    </Box>
  );
}
