/** @jsx jsx */
// @ts-check
import BaseProductsPage from '@bottlebooks/gatsby-theme-event/src/components/ProductsPage/ProductsPage';
import { jsx } from 'theme-ui';

export * from '@bottlebooks/gatsby-theme-event/src/components/ProductsPage/ProductsPage';

export default function ProductsPage(props) {
  return <BaseProductsPage {...props} withEventBanner withEventBannerOverlay={false} />;
}
